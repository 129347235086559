import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { RouteWithLayout } from '../components';
import { StandardTableauDashboard as StandardTableauDashboardView, WorkingOnIt as WorkingOnItView } from '../views';
import { Main as MainLayout } from '../layouts';
import React from 'react';

const TableauRoutes = ({pages}) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <RouteWithLayout
        component={WorkingOnItView}
        exact
        layout={MainLayout}
        path={path}
      />
      {pages && (
        <RouteWithLayout
          component={StandardTableauDashboardView}
          exact
          layout={MainLayout}
          path={`${path}/:page(${pages.map(
            page => page.href).join('|')})`
          }
        />
      )}
      <Redirect to="/not-found"/>
    </Switch>
  );
}

export default TableauRoutes;

import axios from 'axios';
import { getAccessToken } from './graphService';
import { API_URL } from '../constants/apiUrl';

const instance = axios.create({
  baseURL: API_URL.AZURE_FUNCTION,
  headers: {
    common: {
      'Cache-Control': 'no-cache'
    },
  },
});

instance.interceptors.request.use((config) => {
  config.params = {
    accessToken: getAccessToken(),
    code: process.env.REACT_APP_FUNCTION_CODE
  };
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default instance;

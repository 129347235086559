import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DataTable from '../DataTable';
import { CrudDialog } from '../CrudDialog';
import CrudTableToolbar from './CrudTableToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const CrudTable = ({
  crudDialog,
  crudDialogSettings,
  onClickCloseCrudDialog,
  onClickCreate,
  tableSettings,
  toolbarSettings
}) => {
  const classes = useStyles();

  const handleClickCreate = () => {
    if (onClickCreate) onClickCreate();
  }

  const handleClickCloseCrudDialog = () => {
    if (onClickCloseCrudDialog) onClickCloseCrudDialog();
  }

  return (
    <div className={classes.root}>
      <CrudTableToolbar
        toolbarSettings={toolbarSettings}
        onClickCreate={handleClickCreate}
      />
      <div className={classes.content}>
        <DataTable tableSettings={tableSettings} />
      </div>
      <CrudDialog
        crudDialog={crudDialog}
        settings={crudDialogSettings}
        onClose={handleClickCloseCrudDialog}
      />
    </div>
  );
};

CrudTable.propTypes = {
  className: PropTypes.string,
  toolbarSettings: PropTypes.object,
  tableSettings: PropTypes.object
};

export default CrudTable;

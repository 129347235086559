import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Operation } from '../../components/CrudDialog';
import {
  addSidebarLink, deleteSidebarLink, fetchSidebarAndSaveToLocalStorage, getParentLinkId, getSidebar,
  getSidebarMainLinksWithIconComponents, getSidebarSubLinksByParentHref, updateSidebarLink
} from '../../services/sidebarLinkService';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { getMaterialIcons, DynamicMaterialIcon } from '../../services/iconService';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import { getActiveDirectoryGroups } from '../../services/microsoftGraphApiService';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import AppContext from '../../contexts/AppContext';
import Link from '@material-ui/core/Link';
import CrudTable from '../../components/CrudTable';
import axios from '../../services/axiosService';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  permission: {
    margin: theme.spacing(1)
  },
  tableauLink: {
    wordBreak: 'break-all'
  }
}));

const Sidebar = ({ match, location, history }) => {
  const classes = useStyles();
  const isSidebarChildrenPage = !!match.params.page;

  const { enqueueSnackbar } = useSnackbar();
  const { appData, setAppData } = useContext(AppContext);
  const { sidebar_links } = appData;

  let crudDialogSettings = {
    create: {
      onSubmit: addSidebarLink,
      dialogContent: (formData, handleChange, classes) => {
        const activeDirectoryGroups = getActiveDirectoryGroups();
        return (
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Name"
                  value={formData.name}
                  onChange={handleChange('name')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Icon</InputLabel>
                  <Select
                    value={formData.icon}
                    onChange={handleChange('icon')}
                    input={<Input />}
                    renderValue={(selected) => selected}
                    MenuProps={{PaperProps: {
                        style: {
                          width: 250,
                        },
                      }}}
                  >
                    {getMaterialIcons().map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={formData.icon === name} />
                        <Box alignItems="center" display="flex">
                          <Box mr={1} mt={1}>
                            <DynamicMaterialIcon className={classes.icon} icon={name} />
                          </Box>
                          <ListItemText primary={name} />
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>View Permission</InputLabel>
                  <Select
                    multiple
                    value={formData.view_permission || []}
                    onChange={handleChange('view_permission')}
                    input={<Input />}
                    renderValue={
                      selected => selected.map(id =>
                        activeDirectoryGroups
                          .find(group => group.id === id)
                          ?.displayName
                      ).join(', ')
                    }
                    MenuProps={{PaperProps: {
                        style: {
                          width: 250,
                        },
                      }}}
                  >
                    {activeDirectoryGroups.map(({id, displayName}) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox
                          checked={
                            formData.hasOwnProperty('view_permission')
                            && formData.view_permission.indexOf(id) > -1
                          }
                        />
                        <Box alignItems="center" display="flex">
                          <ListItemText primary={displayName} />
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      },
      dialogTitle: (operation) => {
        return <>{ operation === Operation.CREATE ? 'Add' : 'Edit' } Link</>;
      },
      dialogInstructions: (operation) => {
        return <>{ operation === Operation.CREATE ? 'Add' : 'Edit' } the details below to update the sidebar navigation</>;
      }
    },
    update: {
      onSubmit: updateSidebarLink,
    },
    delete: {
      onSubmit: deleteSidebarLink,
      dialogTitle: () => {
        return <>Are you sure?</>;
      },
      dialogInstructions: (operation, selected_item) => {
        return <>This will permanently delete the
          <strong>{selected_item.title}</strong>
          link.</>;
      }
    },
    fetchDataAfterSubmit: fetchSidebarAndSaveToLocalStorage,
    appDataToRefreshAfterSubmit: 'sidebar_links'
  };

  if (isSidebarChildrenPage) {
    crudDialogSettings = {
      ...crudDialogSettings,
      ...{
        create: {
          ...(crudDialogSettings.create),
          extraNonFormDataToSubmit: {
            parent_id: getParentLinkId(match.params.page, sidebar_links)
          },
          dialogContent: (formData, handleChange, classes) => {
            return (
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoFocus
                      fullWidth
                      label="Name"
                      value={formData.name}
                      onChange={handleChange('name')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel>Icon</InputLabel>
                      <Select
                        value={formData.icon}
                        onChange={handleChange('icon')}
                        input={<Input />}
                        renderValue={(selected) => selected}
                        MenuProps={{PaperProps: {
                            style: {
                              width: 250,
                            },
                          }}}
                      >
                        {getMaterialIcons().map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={formData.icon === name} />
                            <Box alignItems="center" display="flex">
                              <Box mr={1} mt={1}>
                                <DynamicMaterialIcon className={classes.icon} icon={name} />
                              </Box>
                              <ListItemText primary={name} />
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Tableau URL"
                      placeholder="Example: ABGAvgDispatchTime/AvgDispatchTime"
                      margin="dense"
                      value={formData.tableau_url}
                      onChange={handleChange('tableau_url')}
                      onBlur={() => {
                        // if user enters full url, remove the beginning
                        let match = formData.tableau_url
                          && formData
                            .tableau_url
                            .match(/^https?:(?:.*)views\/(.*)/i);
                        if (match !== null && typeof match !== 'undefined') {
                          handleChange('tableau_url', match[1]);
                        }
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            );
          }
        }
      }
    }
  }

  const links = isSidebarChildrenPage
    ? getSidebarSubLinksByParentHref(match.params.page, sidebar_links)
    : getSidebarMainLinksWithIconComponents(sidebar_links);

  // table settings
  const { active_directory_groups } = appData;

  const [crudDialog, setCrudDialog] = useState(
    { open: false, operation: null, link: null }
  );

  const handleClickCreate = () => {
    setCrudDialog({
      open: true,
      operation: Operation.CREATE,
      selected_item: null
    });
  };

  const handleCloseCrudDialog = () => {
    setCrudDialog({
      open: false,
      operation: null,
      selected_item: null
    });
  };

  const handleEditClick = (event, link) => {
    event.stopPropagation();
    setCrudDialog({
      open: true,
      operation: Operation.UPDATE,
      selected_item: link
    });
  };
  const handleDeleteClick = (event, link) => {
    event.stopPropagation();
    setCrudDialog({
      open: true,
      operation: Operation.DELETE,
      selected_item: link
    });
  };

  const tableBodyTitle = (link) => (
    <div className={classes.nameContainer}>
      <Typography variant="body1">{link.name}</Typography>
    </div>
  );

  const tableBodyIcon = (link) => (
    <DynamicMaterialIcon icon={link.icon} />
  );

  const tableActions = (link) => (
    <>
      <IconButton aria-label="edit" onClick={(event) => handleEditClick(event, link)}>
        <EditIcon
        />
      </IconButton>
      <IconButton aria-label="delete" onClick={(event) => handleDeleteClick(event, link)}>
        <DeleteIcon
        />
      </IconButton>
    </>
  );

  let tableCellWidths = [
    '10%',
    '10%',
    '60%'
  ];

  const draggableSettings = {
    onDragEnd: (tableData) => async (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const reorder = (list, startId, endId) => {
        const results = Array.from(list);
        const startIndex = results.findIndex((result) => result.id === tableData[startId].id);
        const endIndex = results.findIndex((result) => result.id === tableData[endId].id);
        const [removed] = results.splice(startIndex, 1);
        results.splice(endIndex, 0, removed);

        return results;
      };

      const sidebar_links_before_reorder = appData.sidebar_links;
      const sidebar_links = reorder(
        sidebar_links_before_reorder,
        result.source.index,
        result.destination.index
      );

      const sortOrders = sidebar_links
        .map(({id}, order_id) => ({ id, order_id }));

      setAppData(prevAppData => {
        return { ...prevAppData, sidebar_links };
      });

      try {
        await axios.patch(
          'SidebarLink',
          { sortOrders }
        );

        localStorage.setItem('sidebar_links', JSON.stringify(sidebar_links));
      } catch (error) {
        // revert back to state before reorder on save error
        setAppData(prevAppData => {
          return { ...prevAppData, sidebar_links: sidebar_links_before_reorder };
        });

        enqueueSnackbar(
          "Change wasn't saved",
          { variant: 'error' }
        );
      }
    }
  };

  let tableSettings = {
    tableData: links,
    onRowClick: (event, link) => {
      history.push(`${location.pathname}/${link.href}`);
    },
    tableHead: () => {
      const tHead = [
        'Name',
        'Icon',
        'View Permission',
        'Actions'
      ];
      return tHead.map((entry, ind) => (
        {
          title: entry,
          ...(tableCellWidths[ind] && { width: tableCellWidths[ind] })
        }
      ))
    },
    tableBody: (link) => {
      const tBody = [
        tableBodyTitle(link),
        tableBodyIcon(link),
        link.view_permission.map(permission_id => {
          let display_name = null;
          if (active_directory_groups) {
            let group = active_directory_groups
              .find(group => group.id === permission_id);
            if (group) display_name = group.displayName;
          }
          return <Chip
            key={permission_id}
            size="small"
            label={display_name}
            className={classes.permission}
          />;
        }),
        tableActions(link)
      ];
      return tBody.map((entry, ind) => (
        {
          content: entry,
          ...(tableCellWidths[ind] && { width: tableCellWidths[ind] })
        }
      ))
    },
    draggableSettings
  };

  let toolbarSettings = {
    breadcrumbs: [
      { title: 'Admin', href: '/admin' },
      { title: 'Sidebar Main Categories' }
    ],
    title: 'Sidebar Main Categories',
    buttonText: 'Add Sidebar Main Category'
  };

  if (isSidebarChildrenPage) {
    tableCellWidths = [
      '20%',
      '5%',
      '55%'
    ];

    tableSettings = {
      tableData: links,
      tableHead: () => {
        const tHead = [
          'Name',
          'Icon',
          'Tableau URL',
          'Actions',
        ];
        return tHead.map((entry, ind) => (
          {
            title: entry,
            ...(tableCellWidths[ind] && { width: tableCellWidths[ind] })
          }
        ))
      },
      tableBody: (link) => {
        const tBody = [
          tableBodyTitle(link),
          tableBodyIcon(link),
          (
            <Link
              className={classes.tableauLink}
              target="_blank"
              href={`https://prod-useast-a.online.tableau.com/t/nsd/views/${link.tableau_url}`}
            >
              {link.tableau_url}
            </Link>
          ),
          tableActions(link)
        ];
        return tBody.map((entry, ind) => (
          {
            content: entry,
            ...(tableCellWidths[ind] && { width: tableCellWidths[ind] })
          }
        ))
      },
      draggableSettings
    };

    toolbarSettings = {
      breadcrumbs: [
        { title: 'Admin', href: '/admin' },
        { title: 'Sidebar Main Categories', href: '/admin/sidebar' },
        { title: getSidebar().find((sidebar_link) => sidebar_link.href === match.params.page)?.name }
      ],
      title: 'Tableau Page',
      buttonText: 'Add Tableau Page'
    };
  }

  return (
    <CrudTable
      crudDialog={crudDialog}
      tableSettings={tableSettings}
      toolbarSettings={toolbarSettings}
      crudDialogSettings={crudDialogSettings}
      onClickCreate={handleClickCreate}
      onClickCloseCrudDialog={handleCloseCrudDialog}
    />
  );
};

export default Sidebar;

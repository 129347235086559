import React, { useContext, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Operation from './Operation';
import { makeStyles } from '@material-ui/styles';
import AppContext from '../../contexts/AppContext';
import { green, red } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 260
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonError: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  alert: {
    marginTop: 8,
  },
  hide: {
    display: 'none'
  }
}));

const CrudDialog = ({ settings, crudDialog, onClose }) => {
  const {
    create: {
      onSubmit: createOnSubmit,
      extraNonFormDataToSubmit,
      dialogContent: createDialogContent,
      dialogTitle: createDialogTitle,
      dialogInstructions: createDialogInstructions
    },
    update: { onSubmit: updateOnSubmit },
    delete: {
      onSubmit: deleteOnSubmit,
      dialogTitle: deleteDialogTitle,
      dialogInstructions: deleteDialogInstructions
    },
    fetchDataAfterSubmit,
    appDataToRefreshAfterSubmit
  } = settings;

  const { setAppData } = useContext(AppContext);
  const { open, operation, selected_item } = crudDialog;
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);

  useEffect(() => {
    if (open === true && operation === Operation.UPDATE) {
      setFormData({ ...formData, ...selected_item });
    } else if (open === false) {
      setFormData({});
    }
  }, [open, selected_item, operation]);

  const updateAppDataContext = async () => {
    let dataAfterSubmit = await fetchDataAfterSubmit();
    setAppData(prevAppData => {
      return { ...prevAppData, [appDataToRefreshAfterSubmit]: dataAfterSubmit };
    });
  }

  const handleSave = async () => {
    setLoading(true);
    const dataToSubmit = { ...formData, ...extraNonFormDataToSubmit };
    const { id, ...dataToSubmitWithoutId } = dataToSubmit;

    try {
      operation === Operation.CREATE
        ? await createOnSubmit(dataToSubmitWithoutId)
        : await updateOnSubmit(dataToSubmit);
      await updateAppDataContext();
      handleClose();
    } catch (e) {
      const {response: {data: {error}}} = e;
      setAlertMessage(error);
    }

    setLoading(false);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    const { id } = selected_item;

    try {
      await deleteOnSubmit({ id });
      await updateAppDataContext();
      handleClose();
    } catch (e) {
      const {response: {data: {error}}} = e;
      setAlertMessage(error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setAlertMessage(null);
    if (onClose) onClose();
  };

  const classes = useStyles();

  // update field with either what the user is typing or newVal
  const handleChange = (field, newVal) => {
    if (newVal !== undefined) {
      setFormData({ ...formData, [field]: newVal });
    } else {
      return (e) => {
        setFormData({ ...formData, [field]: e.target.value });
      }
    }
  };

  let dialog = {};
  switch(operation) {
    case Operation.CREATE:
    case Operation.UPDATE:
      dialog = {
        dialog_title: createDialogTitle(operation),
        dialog_instructions: createDialogInstructions(operation),
        dialog_content: createDialogContent(formData, handleChange, classes),
        dialog_actions: (
          <>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                className={classes.buttonSuccess}
                onClick={handleSave}
              >
                Save
              </Button>
              {loading && <CircularProgress
                size={24}
                className={clsx(classes.buttonProgress, classes.green)} />
              }
            </div>
          </>
        )
      };
      break;
    case Operation.DELETE:
      dialog = {
        dialog_title: deleteDialogTitle(),
        dialog_instructions: deleteDialogInstructions(operation, selected_item),
        dialog_actions: (
          <>
            <Button onClick={handleClose} disabled={loading} color="primary">
              Don't Delete
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleDeleteConfirm}
                color="primary"
                disabled={loading}
                variant="contained"
                className={classes.buttonError}
                autoFocus
              >
                Delete
              </Button>
              {loading && <CircularProgress
                size={24}
                className={clsx(classes.buttonProgress, classes.red)
                } />}
            </div>
          </>
        )
      };
      break;
    default:
      break;
  }

  let {
    dialog_title,
    dialog_instructions,
    dialog_content,
    dialog_actions
  } = dialog;
  return (
    open === true
      ? <div>
          <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle>
              { dialog_title }
              <CrudAlert message={alertMessage} setMessage={setAlertMessage} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{ dialog_instructions }</DialogContentText>
              { dialog_content }
            </DialogContent>
            <DialogActions>{ dialog_actions }</DialogActions>
          </Dialog>
        </div>
      : null
  );
}

function CrudAlert({message, setMessage}) {
  const useStyles = makeStyles((theme) => ({
    alert: {
      marginTop: 8,
    },
    hide: {
      display: 'none'
    }
  }));

  const classes = useStyles();

  return (
    <Alert className={clsx({
              [classes.hide]: (message === null),
              [classes.alert]: true
           })}
           onClose={() => {setMessage(null)}}
           severity="error">
      { message }
    </Alert>
  );
}

export default CrudDialog;

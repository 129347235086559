import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../contexts/AppContext';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'services/axiosService';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Divider, Drawer, useMediaQuery } from '@material-ui/core';
import { Profile, SidebarNav } from './components';
import SidebarCollapser from './components/SidebarCollapser';
import { fetchSidebarAndSaveToLocalStorage, getSidebarWithChildrenNested } from '../../../../services/sidebarLinkService';
import { fetchActiveDirectoryGroupsAndSaveToLocalStorage } from 'services/microsoftGraphApiService';
import { useAutocomplete } from '@material-ui/lab';
import LoadingScreen from 'components/LoadingScreen';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  desktopCollapse: {
    width: 50,
    overflowX: 'hidden'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, onToggleDesktopCollapse, desktopCollapse, className, ...rest } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const { appData, setAppData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { sidebar_links } = appData;

  const classes = useStyles();

  useEffect(() => {
    const loadSidebar = async () => {
      try {
        setIsLoading(true);

        const { data: grpData } = await axios.get('/me/memberOf');

        localStorage.setItem(
          'user_active_directory_groups',
          JSON.stringify(grpData.value.map(({ id, displayName }) => ({ id, displayName })))
        );

        const sidebar_links = await fetchSidebarAndSaveToLocalStorage();
        setAppData((prevAppData) => ({ ...prevAppData, sidebar_links }));

        const active_directory_groups = await fetchActiveDirectoryGroupsAndSaveToLocalStorage();
        setAppData((prevAppData) => ({ ...prevAppData, active_directory_groups }));
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    loadSidebar();
  }, []);

  if (isLoading) return <LoadingScreen />;

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: clsx({
          [classes.drawer]: true,
          [classes.desktopCollapse]: desktopCollapse
        })
      }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        {isDesktop && <SidebarCollapser
          desktopCollapse={desktopCollapse}
          onClick={onToggleDesktopCollapse} />
        }
        {!desktopCollapse &&
          [
            <Profile key="profile"/>,
            <Divider key="divider" className={classes.divider}/>,
            <SidebarNav
              key="sidebarNav"
              className={classes.nav}
              pages={getSidebarWithChildrenNested(sidebar_links)}
              open={true}
            />
          ]
        }
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;

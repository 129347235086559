import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Hidden } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  image: {
    width: '100%'
    // maxHeight: 250
  }
}));

const Home = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h1'}>Welcome to NSD Operations Dashboard.</Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xl={7}>
          <img
            alt="Product"
            className={classes.image}
            src="/images/digitization-4667376_640.jpg"
          />
        </Grid>
        <Hidden smDown>
          <Grid item sm={12} md={4} xl={5}>
            <img
              alt="Product"
              className={classes.image}
              src="/images/tablet-1075790_640.jpg"
            />
          </Grid>
          <Grid item sm={12} md={4} xl={5}>
            <img
              alt="Product"
              className={classes.image}
              src="/images/business-2846221_640.jpg"
            />
          </Grid>
          <Grid item sm={12} md={6} xl={7}>
            <img
              alt="Product"
              className={classes.image}
              src="/images/stock-1863880_640.jpg"
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default Home;

import { createElement } from 'react';
import {
  AccountBalance,
  AirplanemodeActive,
  Assignment,
  AttachMoney,
  BlurLinear,
  CalendarToday,
  CenterFocusStrong,
  CreditCard,
  Dashboard,
  EmojiSymbols,
  FormatListNumbered,
  Group,
  HelpOutline,
  Language,
  LocalAtm,
  MonetizationOn,
  MonetizationOnOutlined,
  PermDataSetting,
  Phone,
  Settings,
  SpeakerNotes,
  SupervisedUserCircle,
  Timeline,
  TrendingUp,
  Widgets
} from '@material-ui/icons';

const iconMap = {
  'AccountBalance': AccountBalance,
  'AirplanemodeActive': AirplanemodeActive,
  'Assignment': Assignment,
  'AttachMoney': AttachMoney,
  'BlurLinear': BlurLinear,
  'CalendarToday': CalendarToday,
  'CenterFocusStrong': CenterFocusStrong,
  'CreditCard': CreditCard,
  'Dashboard': Dashboard,
  'EmojiSymbols': EmojiSymbols,
  'FormatListNumberedIcon': FormatListNumbered,
  'Group': Group,
  'HelpOutline': HelpOutline,
  'Language': Language,
  'LocalAtm': LocalAtm,
  'MonetizationOn': MonetizationOn,
  'MonetizationOnOutlined': MonetizationOnOutlined,
  'PermDataSetting': PermDataSetting,
  'Phone': Phone,
  'Settings': Settings,
  'SpeakerNotes' : SpeakerNotes,
  'SupervisedUserCircle': SupervisedUserCircle,
  'Timeline': Timeline,
  'TrendingUp': TrendingUp,
  'Widgets': Widgets
};

//dynamically load material icon
const DynamicMaterialIcon = ({ icon, ...rest }) => {
  return iconMap[icon] ? createElement(iconMap[icon]) : null;
}

//get all material icons
const getMaterialIcons = () => {
  if (localStorage.getItem('material_icons') === null) {
    localStorage.setItem(
      'material_icons',
      JSON.stringify(Object.keys(iconMap))
    );
  }

  return JSON.parse(localStorage.getItem('material_icons'));
}

export { DynamicMaterialIcon, getMaterialIcons }

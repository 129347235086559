import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { RouteWithLayout } from '../components';
import { WorkingOnIt as WorkingOnItView } from '../views';
import { Main as MainLayout } from '../layouts';
import React from 'react';
import AdminSidebarRoutes from './AdminSidebarRoutes';

const AdminRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <RouteWithLayout
        component={WorkingOnItView}
        exact
        layout={MainLayout}
        path={path}
      />
      <RouteWithLayout path={`${path}/sidebar`}>
        <AdminSidebarRoutes />
      </RouteWithLayout>
      <Redirect to="/not-found"/>
    </Switch>
  );
}

export default AdminRoutes;

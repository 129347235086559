/* eslint-disable no-empty */
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '4200006f-96c9-41d1-be91-7bfd3aa0a382',
    authority: 'https://login.microsoftonline.com/f797e736-cc74-4a7b-82b5-b7a09789eed2'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [msalInstance] = useState(new msal.PublicClientApplication(msalConfig));
  const [authResponse, setAuthResponse] = useState(null);
  const [loginError, setLoginError] = useState(null);

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  };

  const displayError = (error) => {
    console.error(error);
    setLoginError(error.message);
  };

  const loginWithMicrosoft = async () => {
    try {
      const token = await msalInstance.acquireTokenSilent({ scopes: ['User.Read'] });
      setAuthResponse(token);
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError || error instanceof msal.BrowserAuthError) {
        msalInstance.acquireTokenRedirect({ scopes: ['User.Read'] });
        return;
      }

      displayError(error);
    }
  };

  const logout = () => {
    setAuthResponse(null);
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
  };

  useEffect(() => {
    // check if user is alrady logged in
    const setActiveAccount = async () => {
      try {
        const resp = await msalInstance.handleRedirectPromise();
        if (resp !== null) {
          msalInstance.setActiveAccount(resp.account);
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts.length >= 1) {
            const activeAccount = currentAccounts[0];
            msalInstance.setActiveAccount(activeAccount);
          }
        }
      } catch (error) {
        displayError(error);
      }

      try {
        const token = await msalInstance.acquireTokenSilent({ scopes: ['User.Read'] });
        setAuthResponse(token);
      } catch (error) {
      }
    };

    setActiveAccount();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authResponse,
        loginWithMicrosoft,
        logout,
        displayError,
        loginError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;

import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { RouteWithLayout } from '../components';
import {
  Account as AccountView,
  Dashboard as DashboardView,
  ProductList as ProductListView, Settings as SettingsView,
  WorkingOnIt as WorkingOnItView
} from '../views';
import { Main as MainLayout } from '../layouts';
import React from 'react';

const ThemeRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <RouteWithLayout
        component={WorkingOnItView}
        exact
        layout={MainLayout}
        path={path}
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path={`${path}/dashboard`}
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path={`${path}/products`}
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path={`${path}/account`}
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path={`${path}/settings`}
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
}

export default ThemeRoutes;

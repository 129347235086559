const getAccessToken = () => {
  const msData = localStorage.getItem('msData')
    ? JSON.parse(localStorage.getItem('msData'))
    : null;

  return msData
    ? msData.accessToken
    : null;
};

export {
  getAccessToken
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const CrudTableToolbar = ({
  className,
  toolbarSettings,
  onClickCreate,
  ...rest
}) => {
  const classes = useStyles();
  const { breadcrumbs, buttonText, title } = toolbarSettings;

  const handleAddClick = () => {
    if (onClickCreate) onClickCreate();
  };

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {
            breadcrumbs.map((breadcrumb) => (
              breadcrumb.href
                ? (
                  <Link
                    key={breadcrumb.title}
                    variant="body1"
                    color="inherit"
                    to={breadcrumb.href}
                    component={RouterLink}
                  >
                    { breadcrumb.title }
                  </Link>
                )
                : (
                  <Typography
                    key={breadcrumb.title}
                    variant="body1"
                    color="textPrimary"
                  >
                    { breadcrumb.title }
                  </Typography>
                )
            ))
          }
        </Breadcrumbs>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          { title }
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddClick}
        >
          { buttonText }
        </Button>
      </Grid>
    </Grid>
  );
};

CrudTableToolbar.propTypes = {
  className: PropTypes.string
};

export default CrudTableToolbar;

import axios from './axiosService';

// Sidebar links can have children. This adds all children to their parent links
const getChildren = (map, parent_id) => {
  let ans = [];
  map.forEach((val) => {
    if (val.parent_id === parent_id) {
      let { parent_id, ...temp } = val;
      let children = getChildren(map, val.id);
      ans.push({ ...temp, ...(children.length > 0 && {children}) });
    }
  });
  return ans;
};

const getSidebar = () => {
  return localStorage.getItem('sidebar_links') !== null
    ? JSON.parse(localStorage.getItem('sidebar_links'))
    : [];
}

const getSidebarMainLinks = (sidebar_links) => sidebar_links.filter(
  link => link.parent_id === null
);

const getSidebarMainLinksWithIconComponents = (sidebar_links) =>
  getSidebarMainLinks(sidebar_links);

const getSidebarSubLinksByParentHref = (parent_href, sidebar_links) => {
  if (sidebar_links.length === 0) return [];
  const parent_id = sidebar_links.find(page => page.href === parent_href).id
  return sidebar_links.filter(page => page.parent_id === parent_id);
}

const getParentLinkId = (parent_href, sidebar_links) => {
  return sidebar_links.find(page => page.href === parent_href).id;
}

const getSidebarWithChildrenNested = (sidebar_links) => {
  if (sidebar_links.length === 0) return [];
  return getChildren(sidebar_links, null);
}

const fetchSidebarAndSaveToLocalStorage = async () => {
  localStorage.setItem(
    'sidebar_links',
    JSON.stringify((await getSidebarLinks())?.data?.rows)
  );

  return getSidebar();
}

const getSidebarLinks = async () => {
  return await axios.get('SidebarLink');
}

const addSidebarLink = async (
  { name, icon, view_permission, parent_id, tableau_url }
) => {
  await axios.post(
    'SidebarLink',
    { name, icon, view_permission, parent_id, tableau_url }
  )
}

const updateSidebarLink = async ({ id, name, icon, view_permission, parent_id, tableau_url }) => {
  await axios.put(
  'SidebarLink',
  { id, name, icon, view_permission, parent_id, tableau_url }
  )
}

const deleteSidebarLink = async ({ id }) => {
  await axios.delete('SidebarLink', { data: { id } })
}

export {
  fetchSidebarAndSaveToLocalStorage,
  getSidebarWithChildrenNested,
  getSidebarSubLinksByParentHref,
  getSidebar,
  getSidebarMainLinks,
  getSidebarMainLinksWithIconComponents,
  addSidebarLink,
  updateSidebarLink,
  deleteSidebarLink,
  getParentLinkId
}

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import axios from '../../../../../../services/axiosService';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const [userName, setUserName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [avatar, setAvatar] = useState('');
  const classes = useStyles();

  const user = {
    name: userName,
    avatar: avatar,
    bio: jobTitle
  };

  useEffect(() => {
    (async() => {
      let msData = localStorage.getItem('msData') ? JSON.parse(localStorage.getItem('msData')) : null;
      if (msData) {
        setUserName(msData.account.name);
        const msAccesToken = msData.accessToken;

        try {
          const { data: meData } = await axios.get('/me');

          if (meData && meData.jobTitle) {
            setJobTitle(meData.jobTitle);
          }

          const { data: photoData } = await axios.get('/me/photo');

          setAvatar(URL.createObjectURL(photoData));
        } catch (err) {
          console.log('graph error', err);
        }

      }
    })();
  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        src={user.avatar ?? null}
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

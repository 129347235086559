import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

const auth = {
  isAuthenticated() {
    let msData = localStorage.getItem('msData') ? JSON.parse(localStorage.getItem('msData')) : null;
    let sessionExpired = true;
    if (msData) {
      let expirationTime = msData.expiresOn;
      if (moment(new Date()) < moment(expirationTime)) {
        sessionExpired = false;
      }
    }
    const loggedIn = localStorage.getItem('id_token');
    return loggedIn && loggedIn === '1' && !sessionExpired ? true : false;
  }
};

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, publicRoute, ...rest } = props;

  const componentPrefix = 'component-';
  const componentProps = Object.keys(rest).filter(
    key => key.startsWith(componentPrefix)
  ).reduce((acc, cur) => {
    acc[cur.replace(componentPrefix, '')] = rest[cur];
    return acc;
  }, {});

  return (
    <Route
      {...rest}
      render={matchProps =>
        auth.isAuthenticated() || publicRoute ? (
          <Layout>
            <Component {...matchProps} {...componentProps} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string
};

export default RouteWithLayout;

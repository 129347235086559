import React, { cloneElement } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TableBody } from '@material-ui/core';
import { colors } from '@material-ui/core';

const DraggableTable = ({ tableData, tableRow, draggableSettings }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
    ...(isDragging && { display: 'table', background: colors.grey[400] }),
  });

  const getListStyle = isDraggingOver => ({
  });

  const { onDragEnd } = draggableSettings;

  return (
    <DragDropContext onDragEnd={onDragEnd(tableData)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <TableBody
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {tableData.map((row, index) => (
              <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                {(provided, snapshot) => {
                  const tRow = tableRow(row);
                  return cloneElement(tRow, {
                    ref: provided.innerRef,
                    ...provided.draggableProps,
                    ...provided.dragHandleProps,
                    style: getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )
                  });
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableTable.propTypes = {
};

export default DraggableTable;

import axios from './axiosService';
import { getAccessToken } from './graphService';

const getLoggedInUserActiveDirectoryGroups = () => (localStorage.getItem('user_active_directory_groups') !== null
  ? JSON.parse(localStorage.getItem('user_active_directory_groups'))
  : []);

const getActiveDirectoryGroups = () => (localStorage.getItem('active_directory_groups') !== null
  ? JSON.parse(localStorage.getItem('active_directory_groups'))
  : []);

const hasAccessToApp = () => getLoggedInUserActiveDirectoryGroups()
  .some(({ displayName }) => displayName.startsWith('All InsightQore'));

const isAdmin = () => getLoggedInUserActiveDirectoryGroups()
  .some(({ displayName }) => displayName === 'All InsightQore Admin');

const fetchActiveDirectoryGroupsAndSaveToLocalStorage = async () => {
  const azureGroups = (
    await axios.get('/groups')
  ).data.value;

  const azureGroupsSorted = azureGroups.sort((a, b) => {
    if (a.displayName < b.displayName) { return -1; }
    if (a.displayName > b.displayName) { return 1; }
    return 0;
  });

  localStorage.setItem(
    'active_directory_groups',
    JSON.stringify(azureGroupsSorted)
  );

  return getActiveDirectoryGroups();
};

export {
  getAccessToken,
  fetchActiveDirectoryGroupsAndSaveToLocalStorage,
  getActiveDirectoryGroups,
  hasAccessToApp,
  isAdmin
};

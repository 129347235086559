import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Button, Grid, Typography, useTheme
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useAuth from '../../hooks/useAuth';
import axios from '../../services/axiosService';
import {
  fetchActiveDirectoryGroupsAndSaveToLocalStorage,
  hasAccessToApp
} from '../../services/microsoftGraphApiService';
import AppContext from '../../contexts/AppContext';
import {
  fetchSidebarAndSaveToLocalStorage,
} from '../../services/sidebarLinkService';
import LoadingScreen from '../../components/LoadingScreen';
import Office365Logo from '../../icons/Office365';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/login_bg.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = (props) => {
  const { history } = props;

  const theme = useTheme();
  const classes = useStyles();
  const {
    authResponse,
    loginWithMicrosoft,
    displayError,
    loginError
  } = useAuth();

  const { setAppData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    event.persist();
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleSignIn = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // redirect from login with Microsoft comes with a hash in the url
    if (window.location.hash) {
      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    const login = async () => {
      try {
        setIsLoading(true);

        localStorage.setItem('id_token', '1');
        localStorage.setItem('msData', JSON.stringify(authResponse));
        const { data: grpData } = await axios.get('/me/memberOf');

        localStorage.setItem(
          'user_active_directory_groups',
          JSON.stringify(grpData.value.map(({ id, displayName }) => ({ id, displayName })))
        );

        if (!hasAccessToApp()) {
          throw new Error('You are not authorized to access this application');
        }

        const sidebar_links = await fetchSidebarAndSaveToLocalStorage();
        setAppData((prevAppData) => ({ ...prevAppData, sidebar_links }));

        const active_directory_groups = await fetchActiveDirectoryGroupsAndSaveToLocalStorage();
        setAppData((prevAppData) => ({ ...prevAppData, active_directory_groups }));

        history.push('/');
      } catch (error) {
        displayError(error);
      }

      setIsLoading(false);
    };

    if (authResponse) {
      login();
    }
  }, [authResponse]);

  if (isLoading) return <LoadingScreen />;

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                It is a capital mistake to theorize before one has data.
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >
                  (Sherlock Holmes)
                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                  NSD Insight Qore
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader} />
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Sign in
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Sign in using NSD O365 credentials
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={loginWithMicrosoft}
                    >
                      <Office365Logo className={classes.socialIcon} />
                      Sign In Using NSD O365 Credentials
                    </Button>
                    {loginError && (
                    <Typography variant="h3" style={{ color: theme.palette.error.main }}>
                      {loginError}
                    </Typography>
                    )}
                  </Grid>
                  <Grid item />
                </Grid>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  or request access
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Request Access
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

// Check if we're in development or production. Staging uses prod db and functions
export const getCurrentEnv = () => {
  const currentHost = window.location.host;
  let currentEnv;

  if (currentHost.includes(`-${ENVIRONMENT.DEVELOPMENT}`)
    || currentHost.includes(`localhost`)
  ) {
    currentEnv = ENVIRONMENT.DEVELOPMENT;
  } else if (currentHost.includes(`-${ENVIRONMENT.STAGING}`)) {
    currentEnv = ENVIRONMENT.STAGING;
  } else {
    currentEnv = ENVIRONMENT.PRODUCTION;
  }

  return currentEnv;
}

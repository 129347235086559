import React, { Suspense, useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import workingOnItPages from './content/workingOnItPages';
import {
  NotFound as NotFoundView,
  SignIn as SignInView,
  SignUp as SignUpView,
  WorkingOnIt as WorkingOnItView,
  Home as HomeView,
} from './views';
import AppContext from './contexts/AppContext';
import { getSidebarWithChildrenNested } from './services/sidebarLinkService';
import TableauRoutes from './routes/TableauRoutes';
import AdminRoutes from './routes/AdminRoutes';
import { isAdmin } from './services/microsoftGraphApiService';
import ThemeRoutes from './routes/ThemeRoutes';

const Routes = () => {

  const { appData } = useContext(AppContext);
  const { sidebar_links } = appData;

  let routes = [];
  getSidebarWithChildrenNested(sidebar_links).forEach(page => {
    routes.push(
      <RouteWithLayout key={page.id} path={'/' + page.href}>
        <TableauRoutes pages={page.children} />
      </RouteWithLayout>
    );
  });

  return (
    <Suspense fallback={null}>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/home"
        />
        {isAdmin() && (
          <RouteWithLayout path="/admin">
            <AdminRoutes />
          </RouteWithLayout>
        )}
        <RouteWithLayout
          component={HomeView}
          exact
          layout={MainLayout}
          path="/home"
        />

        {/* dynamic tableau pages from db */}
        { getSidebarWithChildrenNested(sidebar_links).map(page =>
          <RouteWithLayout key={page.id} path={'/' + page.href}>
            <TableauRoutes pages={page.children} />
          </RouteWithLayout>
        ) }

        {/* working on it pages */}
        { workingOnItPages.map(path =>
          <RouteWithLayout
            key={path}
            component={WorkingOnItView}
            exact
            layout={MainLayout}
            path={path}
          />
        ) }

        {/* theme pages */}
        <RouteWithLayout path="/theme">
          <ThemeRoutes />
        </RouteWithLayout>

        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/sign-up"
        />
        <RouteWithLayout
          component={SignInView}
          exact
          publicRoute={true}
          layout={MinimalLayout}
          path="/sign-in"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <Redirect to="/not-found"/>
      </Switch>
    </Suspense>
  );
};

export default Routes;

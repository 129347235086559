import React from 'react';
import ReactDOM from 'react-dom';
import './fontello/css/fontello.css';

import * as serviceWorker from './serviceWorker';
import App from './App';

if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
  window.location = `https://${window.location.host}${window.location.pathname}${window.location.search}$`;
}
ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();

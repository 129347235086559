import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  collapser: {
    display: 'inline-block',
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

const SidebarCollapser = ({onClick, desktopCollapse}) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick}
                size='small'
                className={clsx({
                  [classes.collapser]: true,
                  [classes.hidden]: desktopCollapse
                })}>
      { desktopCollapse ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </IconButton>
  );
};

export default SidebarCollapser;

import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import './fontello/css/fontello.css';
import AppContext from './contexts/AppContext';
import { getSidebar } from './services/sidebarLinkService';
import {
  getActiveDirectoryGroups
} from './services/microsoftGraphApiService';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from 'contexts/AuthContext';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const [appData, setAppData] = useState({
    sidebar_links: getSidebar(),
    active_directory_groups: getActiveDirectoryGroups(),
  });

  return (
    <AuthProvider>
      <AppContext.Provider value={{ appData, setAppData }}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={1} autoHideDuration={2500}>
            <Router history={browserHistory}>
              <Routes/>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </AuthProvider>
  );
};

export default App;

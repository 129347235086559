import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 10,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 350
  },
  topImage: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 150
  }
}));

const WorkingOnIt = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <img
              alt="Under development"
              className={classes.topImage}
              src="/images/work-47200_640.png"
            />
            <Typography variant="h1">
              204: This page isn't finished
            </Typography>
            <Typography variant="subtitle2">
              We are working on it! Hang in there we will be done soon!!
            </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src="http://giphygifs.s3.amazonaws.com/media/2YOp8WqFDRHhe/giphy.gif"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default WorkingOnIt;

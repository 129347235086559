import React from 'react';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'white',
    width: 13,
    marginRight: theme.spacing(1)
  },
  path: {
    // fill:'#eb3c00',
    fillOpacity:1,
    strokeWidth:.31999999
  }
}));

const Office365Logo = (props) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 300 300" className={classes.icon} {...props}>
      <path
        className={classes.path}
        d="M 154.66 0 C 154.3 0 0.29000000000000004 57.78 0.08 58 C 0 58.07 -0.020000000000000004 97.03 0.020000000000000004 144.57 L 0.1 231 L 19.3 223.49 C 29.86 219.36 41.64 214.75 45.46 213.24 L 52.42 210.51000000000002 L 52.42 140.15000000000003 C 52.42 91.62000000000003 52.52 69.73000000000003 52.760000000000005 69.59000000000003 C 53.34 69.23000000000003 154.32 45.17000000000003 154.57999999999998 45.320000000000036 C 154.89999999999998 45.52000000000004 154.89999999999998 252.07000000000005 154.57999999999998 252.26000000000005 C 154.45 252.34000000000006 119.99999999999999 247.67000000000004 78.01999999999998 241.89000000000004 C 36.05 236.1 1.6 231.4 1.48 231.44 C 1.35 231.49 35.55 244.21 77.48 259.73 C 119.4 275.24 154.06 287.95000000000005 154.5 287.97 C 154.94 287.98 174.42000000000002 282.71000000000004 197.78 276.25 L 240.26 264.5 L 240.17999999999998 144.53 L 240.09999999999997 24.560000000000002 L 197.53999999999996 12.280000000000003 C 174.14 5.53 154.84 0 154.66 0 Z"
      />
    </SvgIcon>
  );
}

export default Office365Logo;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import TableauReport from 'tableau-react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const options = {
  height: 'calc(100vh - 150px)',
  width: '100%',
  hideTabs: false
};
const TableauDashboard = props => {
  const { url } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableauReport
        url={`https://prod-useast-a.online.tableau.com/t/nsd/views/${url}`}
        options={options} // vizCreate options
        // Overwrite default query params
        // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
        query="?:embed=yes:&showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
      />
    </div>
  );
};

TableauDashboard.propTypes = {
  url: PropTypes.string
};


export default TableauDashboard;

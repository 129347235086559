import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { RouteWithLayout } from '../components';
import {
  Sidebar as SidebarView,
} from '../views';
import { Main as MainLayout } from '../layouts';
import { getSidebarWithChildrenNested } from '../services/sidebarLinkService';

const AdminSidebarRoutes = () => {
  let { path } = useRouteMatch();
  const { appData } = useContext(AppContext);
  const { sidebar_links } = appData;

  return (
    <Switch>
      <RouteWithLayout
        component={SidebarView}
        exact
        layout={MainLayout}
        path={path}
      />
      <RouteWithLayout
        component={SidebarView}
        exact
        layout={MainLayout}
        path={`${path}/:page(${getSidebarWithChildrenNested(sidebar_links).map(
          page => page.href).join('|')})`
        }
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
}

export default AdminSidebarRoutes;

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import DraggableTable from './DraggableTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableRow: {
    cursor: 'pointer'
  },
  checkboxCell: {
    width:52
  }
}));

const DataTable = ({ className, tableSettings, draggable, ...rest }) => {
  const classes = useStyles();
  const {
    tableHead,
    tableBody,
    onRowClick,
    tableData,
    draggableSettings
  } = tableSettings;

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [tableSettings]);

  const applyPagination = (data, page, limit) => {
    return data.slice(page * limit, page * limit + limit);
  };

  const handleSelectAll = event => {

    let selectedRows;

    if (event.target.checked) {
      selectedRows = tableData.map(row => row.id);
    } else {
      selectedRows = [];
    }

    setSelectedRows(selectedRows);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleRowClick = (event, row) => {
    if (onRowClick) onRowClick(event, row);
  };

  const handleClickCheckboxCell = (event) => {
    event.stopPropagation();
  };

  let currentTableData = applyPagination(tableData, page, rowsPerPage)
    .slice(0, rowsPerPage);

  const tableRow = (row) => (
    <TableRow
      className={classes.tableRow}
      hover
      key={row.id}
      selected={selectedRows.indexOf(row.id) !== -1}
      onClick={(event) => handleRowClick(event, row)}
    >
      <TableCell
        padding="checkbox"
        onClick={handleClickCheckboxCell}
        className={classes.checkboxCell}
      >
        <Checkbox
          checked={selectedRows.indexOf(row.id) !== -1}
          color="primary"
          onChange={event => handleSelectOne(event, row.id)}
          value="true"
        />
      </TableCell>
      {
        tableBody(row).map((tableCell, idx) => (
          <TableCell
            key={idx}
            width={tableCell.width ?? 'auto'}
          >
            { tableCell.content ?? tableCell }
          </TableCell>
        ))
      }
    </TableRow>
  );

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table style={{tableLayout:'fixed'}}>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    className={classes.checkboxCell}
                  >
                    <Checkbox
                      checked={selectedRows.length === currentTableData.length}
                      color="primary"
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < currentTableData.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {
                    tableHead().map((head) => (
                      <TableCell
                        width={head.width ?? 'auto'}
                        key={head.title ?? head}>{ head.title ?? head }
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              {
                draggableSettings ? (
                  <DraggableTable
                    tableData={currentTableData}
                    tableRow={tableRow}
                    draggableSettings={draggableSettings}
                  />
                ) : (
                  <TableBody>
                    { currentTableData.map((row) => tableRow(row)) }
                  </TableBody>
                )
              }
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={tableData.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  tableSettings: PropTypes.object.isRequired,
  draggable: PropTypes.bool
};

DataTable.defaultProps = {
  draggable: true
}

export default DataTable;

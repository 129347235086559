import { ENVIRONMENT, getCurrentEnv } from '../services/environmentService';

const getAzureFunctionUrl = () => {
  let azureFunctionUrl;
  const currentEnv = getCurrentEnv();
  switch (currentEnv) {
    case ENVIRONMENT.DEVELOPMENT:
      azureFunctionUrl = 'https://nsd-api-insight-qore-development.azurewebsites.net/api';
      break;
    case ENVIRONMENT.STAGING:
      azureFunctionUrl = 'https://nsd-api-insight-qore-staging.azurewebsites.net/api';
      break;
    case ENVIRONMENT.PRODUCTION:
      azureFunctionUrl = 'https://nsd-api-insight-qore.azurewebsites.net/api';
      break;
    default:
      break;
  }

  return azureFunctionUrl;
};

export const API_URL = {
  AZURE_FUNCTION: getAzureFunctionUrl(),
  MICROSOFT_GRAPH: 'https://graph.microsoft.com/v1.0'
};

import React from 'react';
import {
  IconButton,
  Tooltip
} from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

function Admin() {
  return (
    <>
      <Tooltip title="Admin">
        <IconButton
          color="inherit"
          component={RouterLink}
          to="/admin/sidebar"
        >
          <LockOpenIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Admin;

import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import TableauDashboard from '../../components/Tableau';
import { withRouter } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));


const StandardTableauDashboard = (props) => {
  const { match } = props;
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const { appData } = useContext(AppContext);
  const { sidebar_links } = appData;

  useEffect(() => {
    const sidebar_link = sidebar_links.find(page =>
      page.href === match.params.page
    );
    const { tableau_url } = sidebar_link;

    setUrl(tableau_url);
  }, [match.params.page, sidebar_links]);

  return (
    <div className={classes.root}>
      <TableauDashboard url={url}/>
    </div>
  );
};

export default withRouter(StandardTableauDashboard);
